<template>
  <v-container id="train" fluid tag="section" class="container-full-size custom-plan">
    <v-card v-if="train">
      <v-card-title>
        <span class="headline">
          {{ title ? title : str['training'] }}
        </span>
      </v-card-title>
      <v-col cols="12">
        <v-col cols="12" class="data-container-title">
          <div class="row-flex-align-space">
            <v-text-field
              v-model="train.name"
              :style="{ 'pointer-events': editPermission ? '' : 'none' }"
              :label="str['train_name']"
              class="purple-input"
              style="margin: 0"
            />
          </div>
          <div v-if="showTrainDetails">
            <v-textarea
              v-model="train.remarks"
              :style="{ 'pointer-events': editPermission ? '' : 'none' }"
              :label="str['remarks']"
              style="margin: 0"
            />
          </div>
          <div class="text-right">
            <v-btn v-if="editPermission && client" color="blue" class="mr-0" @click="openBaseTrains()">
              {{ str['use_base'] }}
            </v-btn>
            <v-btn v-if="editPermission" color="success" class="mr-0" style="margin-left: 10px" @click="newStep()">
              {{ str['add_exercise'] }}
            </v-btn>
            <v-btn v-if="editPermission && hasCircuits" color="success" class="mr-0" style="margin-left: 10px" @click="openCircuitStep()">
              {{ str['add_circuit'] }}
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12">
          <div>
            <h4 style="margin-bottom: 10px">
              {{ str['exercises'] }}
            </h4>
          </div>
          <div v-if="!train.steps.length" class="pt-8 row-flex-align-center" style="position: absolute; left: 0; width: 100%">
            {{ str['empty_list'] }}
          </div>
          <draggable
            :list="train.steps"
            class="sortable-list-group"
            handle=".handle"
            style="min-height: 20vh"
            @start="dragging = true"
            @end="endStepSort"
          >
            <div
              v-for="(trainStep, trainStepIndex) in train.steps"
              v-show="!trainStep.new"
              :key="trainStepIndex"
              class="sortable-list-group-item-box-parent"
              :class="{
                'sortable-list-group-item-circuit-header': isCircuitHeaderStep(trainStep),
                'sortable-list-group-item-circuit-children': !isCircuitHeaderStep(trainStep) && trainStep.circuit,
                'sortable-list-group-item-circuit-children-last': isLastCircuitStep(trainStepIndex),
              }"
            >
              <div class="sortable-list-group-item-box-content">
                <span v-if="isCircuitHeaderStep(trainStep)" @click="editCircuitStep(trainStepIndex)">
                  {{ trainStep.remarks }}{{ trainStep.repeat_id > 1 ? ' - ' + trainStep.repeat_id + 'x' : '' }}
                  <span
                    v-if="isCircuitHeaderStep(trainStep) && isLastCircuitStep(trainStepIndex)"
                    style="font-weight: 400"
                    @click.stop="newStep(trainStep)"
                  >
                    {{ str['add_exercise'] }}
                  </span>
                </span>
                <span v-if="!isCircuitHeaderStep(trainStep)" @click="openStep(trainStepIndex)">
                  <span>
                    {{ workoutsDict[trainStep.workout_id] ? workoutsDict[trainStep.workout_id].name : '' }}
                  </span>
                  <span>
                    {{ getStepInfo(trainStep) }}
                  </span>
                </span>
                <span v-if="editPermission" class="sortable-list-group-item-accordion-header-icons">
                  <v-icon v-if="isCircuitHeaderStep(trainStep)" color="success" style="margin-right: 10px" @click="newStep(trainStep)">
                    mdi-plus-circle
                  </v-icon>
                  <v-icon color="teal lighten-2" style="margin-right: 10px" @click="duplicateStep(trainStepIndex)"
                    >mdi-content-duplicate</v-icon
                  >
                  <v-icon color="error" class="mr-0" @click="deleteStep(trainStepIndex)">mdi-delete</v-icon>
                  <v-icon v-if="!isCircuitHeaderStep(trainStep)" color="default" class="handle" style="margin-left: 10px">
                    mdi-format-align-justify
                  </v-icon>
                </span>
              </div>
            </div>
          </draggable>
        </v-col>
      </v-col>
      <v-card-actions>
        <v-spacer />
        <v-btn color="default" @click="closeCallback">
          {{ str['cancel'] }}
        </v-btn>
        <v-btn v-if="client" color="error" @click="deleteTrain()">
          {{ str['delete'] }}
        </v-btn>
        <v-btn v-if="editPermission" :disabled="!train.name" color="success" @click="save()">
          {{ str['save'] }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-if="step" v-model="dialogStep" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['add_exercise'] }}
          </span>
        </v-card-title>
        <v-card-text class="custom-scroll" style="padding-top: 10px; padding-bottom: 10px; max-height: 70vh">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col cols="12">
                <h4>
                  {{ str['filters'] }}
                </h4>
                <v-row class="mb-2">
                  <v-col v-if="showWorkoutPlace" :cols="6">
                    <v-autocomplete
                      v-model="filters.place"
                      :label="str['place']"
                      item-text="label"
                      item-value="value"
                      :items="workoutPlaces"
                      clearable
                      hide-details
                      :no-data-text="str['no_data']"
                      @change="changeWorkoutFilter()"
                    />
                  </v-col>
                  <v-col v-if="workoutTypes && workoutTypes.length" :cols="6">
                    <v-autocomplete
                      v-model="filters.type"
                      :label="str[filtersNames && filtersNames.type ? filtersNames.type : 'type']"
                      item-text="label"
                      item-value="value"
                      :items="workoutTypes"
                      clearable
                      hide-details
                      :no-data-text="str['no_data']"
                      @change="changeWorkoutFilter()"
                    />
                  </v-col>
                  <v-col v-if="workoutMuscleGroups && workoutMuscleGroups.length" :cols="6">
                    <v-autocomplete
                      v-model="filters.muscle_group"
                      :label="str[filtersNames && filtersNames.muscle_group ? filtersNames.muscle_group : 'muscle_group']"
                      item-text="label"
                      item-value="value"
                      :items="workoutMuscleGroups"
                      clearable
                      hide-details
                      :no-data-text="str['no_data']"
                      @change="changeWorkoutFilter()"
                    />
                  </v-col>
                  <v-col v-if="workoutLevels && workoutLevels.length" :cols="6">
                    <v-autocomplete
                      v-model="filters.level"
                      :label="str['level']"
                      item-text="label"
                      item-value="value"
                      :items="workoutLevels"
                      clearable
                      hide-details
                      :no-data-text="str['no_data']"
                      @change="changeWorkoutFilter()"
                    />
                  </v-col>
                  <v-col v-if="showWorkoutGoalFilter && workoutTargets && workoutTargets.length" :cols="6">
                    <v-autocomplete
                      v-model="filters.target"
                      :label="str['goal']"
                      item-text="label"
                      item-value="value"
                      :items="workoutTargets"
                      clearable
                      hide-details
                      :no-data-text="str['no_data']"
                      @change="changeWorkoutFilter()"
                    />
                  </v-col>
                </v-row>
                <v-divider />
                <h4 style="margin-top: 10px">
                  {{ str['exercise'] }}
                </h4>
                <div>
                  <v-autocomplete
                    v-model="step.workout_id"
                    :label="str['exercise']"
                    item-text="name"
                    item-value="id"
                    :items="workoutsFiltered"
                    :no-data-text="str['no_data']"
                  />
                  <v-text-field v-model="step.weight" :label="str['load_kg']" required />
                  <v-text-field v-model="step.repeat_id" :label="str['series']" required />
                  <v-text-field v-if="showRpe" v-model="step.rpe" :label="str['rpe']" required />
                  <v-text-field v-if="showRir" v-model="step.rir" :label="str['rir']" required />
                  <v-text-field v-model="step.repeat" :label="str['repetitions']" required />
                  <v-text-field v-model="step.value" :label="str['runtime']" required />
                  <v-text-field v-model="step.rest" :label="str['rest']" required />
                  <v-textarea v-if="showRemarks" v-model="step.remarks" :label="str['remarks']" required />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top: 0">
          <v-spacer></v-spacer>
          <v-btn color="default" @click="cancelStep">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn :disabled="!step.workout_id" color="success" @click="saveStep">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogBaseTrains" max-width="400px">
      <v-card class="card-border">
        <v-col :cols="12" class="row-align-right">
          <v-icon class="cursor-hover icon-close" @click.stop="dialogBaseTrains = false">mdi-close</v-icon>
        </v-col>
        <v-col :cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="baseTrainsSearchValue"
            class="pt-0"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
        </v-col>
        <div class="custom-scroll box-select-list">
          <div
            v-for="baseTrain in baseTrainsFiltered"
            :key="baseTrain.id"
            class="box-select-item text-center"
            @click="useBaseTrain(baseTrain)"
          >
            {{ baseTrain.name }}
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-if="hasCircuits && circuitStep" v-model="dialogCircuitStep" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['circuit'] }}
          </span>
        </v-card-title>
        <v-card-text style="padding-top: 10px; padding-bottom: 10px">
          <v-container style="padding-top: 0; padding-bottom: 0">
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="circuitStep.name" :label="str['name']" required />
                <v-text-field
                  v-model="circuitStep.series"
                  :label="str['series']"
                  required
                  oninput="this.value = this.value.replace(/[^0-9-]/g, '').replace(/(\..*)\./g, '$1');"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions style="padding-top: 0">
          <v-spacer></v-spacer>
          <v-btn color="default" @click="closeCircuitStep">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            :disabled="!circuitStep.name || !circuitStep.series || circuitStep.series === '0'"
            @click="saveCircuitStep"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  props: {
    client: Object,
    title: String,
    data: Object,
    date: String,
    closeCallback: Function,
    saveCallback: Function,
  },
  data() {
    const user = Utils.getUser()
    const config = user && user.configurations ? user.configurations : null
    const editPermission = Utils.hasPermission('base_trng_plans_edit')

    let showTrainDetails = false
    if (config && config.training_plans && config.training_plans.show_day_details) {
      showTrainDetails = true
    }

    let hasCircuits = false
    if (config && config.training_plans && config.training_plans.has_circuits) {
      hasCircuits = true
    }

    let showRpe = false
    if (config && config.training_plans && config.training_plans.show_rpe) {
      showRpe = true
    }

    let showRir = false
    if (config && config.training_plans && config.training_plans.show_rir) {
      showRir = true
    }

    let showRemarks = false
    if (config && config.training_plans && config.training_plans.show_remarks) {
      showRemarks = true
    }

    let showWorkoutPlace = false
    if (config && config.workouts && config.workouts.has_place) {
      showWorkoutPlace = true
    }

    let showWorkoutGoalFilter = false
    if (config && config.workouts && config.workouts.has_target) {
      showWorkoutGoalFilter = true
    }

    let filtersNames = false
    if (config && config.workouts && config.workouts.filters_names) {
      filtersNames = config.workouts.filters_names
    }

    return {
      str: window.strings,
      user: user,
      editPermission: editPermission,
      train: this.data,
      showTrainDetails: showTrainDetails,
      hasCircuits: hasCircuits,
      showRpe: showRpe,
      showRir: showRir,
      showRemarks: showRemarks,
      showWorkoutPlace: showWorkoutPlace,
      showWorkoutGoalFilter: showWorkoutGoalFilter,
      filtersNames: filtersNames,
      workouts: [],
      workoutsDict: {},
      workoutsFiltered: [],
      filters: {
        place: null,
        type: null,
        muscle_group: null,
        target: null,
        level: null,
      },
      workoutPlaces: Utils.getWorkoutPlaces(),
      workoutMuscleGroups: Utils.getMuscleGroups(),
      workoutTypes: Utils.getWorkoutTypes(),
      workoutTargets: Utils.getWorkoutTargets(),
      workoutLevels: Utils.getWorkoutLevels(),
      dragging: false,
      step: null,
      dialogStep: false,
      circuitStep: null,
      circuitTag: '#CIRCUITO#',
      dialogCircuitStep: false,
      trngPlanIdBase: -999,
      baseTrains: [],
      dialogBaseTrains: false,
      baseTrainsSearchValue: '',
    }
  },
  computed: {
    baseTrainsFiltered: function () {
      let baseTrains = this.baseTrains
      if (this.baseTrainsSearchValue) {
        const searchValue = this.baseTrainsSearchValue.toLowerCase()
        baseTrains = baseTrains.filter(function (item) {
          return item.name.toLowerCase().indexOf(searchValue) > -1
        })
      }
      return baseTrains
    },
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.$isLoading(true)
    this.getWorkouts()
  },
  methods: {
    getWorkouts: function () {
      const self = this
      Api.getWorkouts(
        {
          useCache: true,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.workouts = response.data
            self.workoutsFiltered = response.data
            response.data.forEach(function (it) {
              self.workoutsDict[it.id] = it
            })
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    openCircuitStep: function () {
      this.circuitStep = {
        name: '',
        series: '1',
        editIndex: null,
      }
      this.dialogCircuitStep = true
    },
    closeCircuitStep: function () {
      this.dialogCircuitStep = false
      this.circuitStep = null
    },
    editCircuitStep: function (index) {
      const step = this.train.steps[index]
      this.circuitStep = {
        name: step.remarks,
        series: step.repeat_id,
        editIndex: index,
      }
      this.dialogCircuitStep = true
    },
    saveCircuitStep: function () {
      let circuitValue = null
      this.resetFilters()
      if (this.circuitStep.editIndex !== null) {
        circuitValue = this.train.steps[this.circuitStep.editIndex].circuit
        this.train.steps[this.circuitStep.editIndex].repeat_id = this.circuitStep.series
        this.train.steps[this.circuitStep.editIndex].remarks = this.circuitStep.name
      } else {
        circuitValue = this.getNewCircuitValue()
        this.train.steps.push({
          workout_id: null,
          repeat_id: this.circuitStep.series,
          repeat: this.circuitTag,
          type: 0,
          value: '',
          place: null,
          gender: null,
          level: null,
          proposal: false,
          remarks: this.circuitStep.name,
          circuit: circuitValue,
        })
      }
      this.closeCircuitStep()
    },
    getNewCircuitValue: function () {
      let lastCircuit = 1
      for (let i = 0; i < this.train.steps.length; i++) {
        if (this.train.steps[i].circuit) {
          lastCircuit = this.train.steps[i].circuit > lastCircuit ? this.train.steps[i].circuit : lastCircuit
        }
      }
      lastCircuit += 1
      return lastCircuit
    },
    openStep: function (index) {
      if (this.editPermission) {
        this.stepIndex = index
        this.step = JSON.parse(JSON.stringify(this.train.steps[index]))
        this.dialogStep = true
      }
    },
    cancelStep: function () {
      if (this.step.new) {
        this.train.steps.splice(this.stepIndex, 1)
      }
      this.stepIndex = null
      this.step = null
      this.dialogStep = false
      this.resetFilters()
    },
    saveStep: function () {
      delete this.step.new
      this.train.steps[this.stepIndex] = this.step
      this.stepIndex = null
      this.step = null
      this.dialogStep = false
      this.resetFilters()
    },
    endStepSort: function (event) {
      const self = this
      this.dragging = false
      if (!this.hasCircuits) {
        return true
      }
      const newIndex = event.newIndex
      if (dragInCircuit()) {
        return true
      }
      if (dragOutCircuit()) {
        return true
      }

      function dragInCircuit() {
        const prev = self.train.steps[newIndex - 1]
        const next = self.train.steps[newIndex + 1]
        if (prev && prev.circuit && next && next.circuit) {
          self.train.steps[newIndex].circuit = prev.circuit
          return true
        }
      }

      function dragOutCircuit() {
        if (self.train.steps[newIndex].circuit) {
          const prev = self.train.steps[newIndex - 1]
          if (prev && prev.circuit === self.train.steps[newIndex].circuit) {
            return true
          }
          self.train.steps[newIndex].circuit = 0
          return true
        }
      }
    },
    isCircuitHeaderStep: function (step) {
      return this.hasCircuits && step.repeat === this.circuitTag ? true : false
    },
    isLastCircuitStep: function (index, train) {
      if (!train) {
        train = this.train
      }
      const step = train.steps[index]
      if (this.hasCircuits && step.circuit) {
        let lastIndex = 0
        for (let i = 0; i < train.steps.length; i++) {
          if (train.steps[i].circuit === step.circuit && !step.new) {
            lastIndex = i
          }
        }
        return lastIndex === index ? true : false
      } else {
        return false
      }
    },
    getStepInfo: function (step) {
      let info = ''
      if (step.repeat_id) {
        info += step.repeat_id + ' ' + window.strings['series'] + ' / '
      }
      if (this.showRpe && step.rpe) {
        info += step.rpe + ' ' + window.strings['rpe'] + ' / '
      }
      if (this.showRir && step.rir) {
        info += step.rir + ' ' + window.strings['rir'] + ' / '
      }
      if (step.value) {
        info += step.value + ' / '
      } else if (step.repeat) {
        info += step.repeat + ' ' + window.strings['reps'] + ' / '
      }
      if (step.weight) {
        info += step.weight + ' kg / '
      }
      if (step.rest) {
        info += step.rest + ' ' + window.strings['rest'] + ' / '
      }
      if (info) {
        info = info.substring(0, info.length - 2)
      }
      return info
    },
    openBaseTrains: function () {
      const self = this
      this.getBaseTrains(function () {
        self.baseTrainsSearchValue = ''
        self.dialogBaseTrains = true
      })
    },
    useBaseTrain: function (item) {
      const self = this
      this.$isLoading(true)
      Api.getTrain(
        {
          id: item.id,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success && response.data && response.data[0]) {
            const fieldsToReplace = ['name', 'remarks', 'steps']
            fieldsToReplace.forEach(function (field) {
              self.train[field] = response.data[0][field]
            })
            self.dialogBaseTrains = false
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getBaseTrains: function (callback) {
      const self = this
      if (this.baseTrains.length) {
        callback()
      } else {
        Api.getTrains(
          {
            fast: true,
          },
          function (response) {
            if (response.success) {
              self.baseTrains = response.data
            }
            callback()
          },
        )
      }
    },
    newStep: function (circuitStep) {
      const step = {
        id: this.getNewStepId(),
        workout_id: null,
        repeat_id: '',
        rpe: '',
        rir: '',
        repeat: '',
        type: 0,
        value: '',
        place: null,
        gender: null,
        level: null,
        proposal: false,
        remarks: '',
        circuit: 0,
        new: true,
      }
      this.resetFilters()
      if (this.hasCircuits && circuitStep) {
        step.circuit = circuitStep.circuit
        let index = 0
        for (let i = 0; i < this.train.steps.length; i++) {
          if (this.train.steps[i].circuit === step.circuit) {
            index = i
          }
        }
        index += 1
        this.train.steps.splice(index, 0, step)
        this.openStep(index)
      } else {
        this.train.steps.push(step)
        this.openStep(this.train.steps.length - 1)
      }
    },
    getNewStepId: function () {
      let newId = 0
      this.train.steps.forEach(function (step) {
        if (step.id && step.id > newId) {
          newId = step.id
        }
      })
      return newId + 1
    },
    duplicateStep: function (index) {
      const clone = JSON.parse(JSON.stringify(this.train.steps[index]))
      clone.id = this.getNewStepId()
      if (this.isCircuitHeaderStep(clone)) {
        clone.circuit = this.getNewCircuitValue()
      }
      this.train.steps.push(clone)
    },
    deleteStep: function (index) {
      const step = this.train.steps[index]
      const circuitHeaderStep = this.isCircuitHeaderStep(step)
      if (circuitHeaderStep) {
        for (let i = this.train.steps.length - 1; i > -1; i--) {
          if (step.circuit === this.train.steps[i].circuit) {
            this.train.steps.splice(i, 1)
          }
        }
      } else {
        this.train.steps.splice(index, 1)
      }
    },
    deleteTrain: function () {
      const self = this
      this.$confirm(window.strings['are_you_sure_delete'], '', 'warning', Utils.getAlertOptions(true, true))
        .then(() => {
          self.$isLoading(true)
          Api.deleteTrain(
            {
              id: self.train.id,
              client_id: self.client ? self.client.dbId : null,
            },
            function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.saveCallback()
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    save: function () {
      const self = this
      const data = JSON.parse(JSON.stringify(this.train))
      data.trng_plan_id = this.trngPlanIdBase
      if (this.client) {
        data.date = this.date
        data.client_id = this.client.dbId
      }
      this.$isLoading(true)
      if (data.id) {
        Api.editTrain(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.saveCallback()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      } else {
        delete data.id
        Api.newTrain(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.saveCallback()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        })
      }
    },
    changeWorkoutFilter: function () {
      const clone = JSON.parse(JSON.stringify(this.workouts))
      const workoutsFiltered = []
      for (let i = 0; i < clone.length; i++) {
        const muscleGroupFiltered =
          this.filters.muscle_group === null ||
          this.filters.muscle_group === clone[i].muscle_group ||
          this.filters.muscle_group === clone[i].muscle_group_2 ||
          this.filters.muscle_group === clone[i].muscle_group_3 ||
          this.filters.muscle_group === clone[i].muscle_group_4
        const typeFiltered = this.filters.type === null || this.filters.type === clone[i].type
        const levelFiltered = this.filters.level === null || this.filters.level === clone[i].level
        const targetFiltered = this.filters.target === null || this.filters.target === clone[i].target
        const placeFiltered = this.filters.place === null || this.filters.place === clone[i].place
        if (muscleGroupFiltered && typeFiltered && levelFiltered && targetFiltered && placeFiltered) {
          workoutsFiltered.push(clone[i])
        }
      }
      this.workoutsFiltered = workoutsFiltered
    },
    resetFilters: function () {
      this.filters = {
        place: null,
        type: null,
        muscle_group: null,
        target: null,
        level: null,
      }
      this.changeWorkoutFilter()
    },
  },
}
</script>
